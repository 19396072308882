<template>
  <!-- <a-page-header @back="() => $router.go(-1)" title="GRUPOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Vigilancia</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Grupos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header> -->

  <PageHeader
    title="GRUPOS"
    :level1="{ title: 'Vigilancia', route: '/menu' }"
    :level2="{ title: 'Grupos', route: '/' }"
    goBack
  />

  <div class="flex flex-wrap mb-4">
    <!--------------- Filtros--------------->
    <fieldset class="border border-solid w-full md:w-1/2 border-gray-300 p-3 px-8 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <GruposFiltro @fetchGrupos="fetchGrupos" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Acciones</legend>

      <GruposAcciones
        :grupo="grupo"
        :isDisabled="isDisabled"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleCode="handleCode"
        @handleContactos="handleContactos"
        @handleChangeState="handleChangeState"
      />
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columns"
      :row-selection="rowSelection"
      :data-source="data"
      size="small"
      :pagination="false"
      :loading="isLoading"
      :custom-row="customRow"
      rowKey="idgrupo"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <!-- <footer class="flex flex-col md:flex-row gap-4 items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer> -->

  <FooterTotalDocs
    :currentPage="currentPage"
    :pageSize="pageSize"
    :totalDocs="totalDocs"
    @fetchData="fetchGrupos"
  />

  <!-- <FooterFuente fuente="PADRÓN NOMINAL" :fecha="fechaIngreso" /> -->

  <!-- <p class="text-gray-400">Fuente: PADRÓN NOMINAL actualizado a la fecha: 11/11/2021</p> -->

  <!--------------- Modal--------------->
  <GruposAddEdit
    v-if="isModalVisible"
    :grupo="grupo"
    @closeModal="closeModal"
    @clearSelectedArrays="clearSelectedArrays"
    @fetchGrupos="fetchGrupos"
  />

  <GruposCode
    v-if="ismodalQuery"
    :grupo="grupo"
    :isVisible="ismodalQuery"
    @closeModal="closeModal"
  />

  <GruposContactos
    v-if="ismodalContacto"
    :grupo="grupo"
    :currentPage="currentPage"
    :isVisible="ismodalContacto"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref, reactive, onMounted, watch, computed, toRaw, createVNode } from "vue";
import { columns } from "./utilsGrupos";
import GruposAddEdit from "./GruposAddEdit.vue";
import GruposFiltro from "./GruposFiltro.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterTotalDocs from "@/components/FooterTotalDocs.vue";
import FooterFuente from "@/components/FooterFuente.vue";
import UsuariosApi from "@/api/user";
import {
  ReloadOutlined,
  FileZipOutlined,
  UsergroupAddOutlined,
  IssuesCloseOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined
} from "@ant-design/icons-vue";
import GrupoApi from "@/api/sms_grupo";
import GruposCode from "./GruposCode.vue";
import GruposContactos from "./GruposContactos.vue";
import GruposAcciones from "./GruposAcciones.vue";
import { Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";

export default {
  name: "AppGrupos",
  components: {
    GruposAddEdit,
    GruposContactos,
    GruposCode,
    GruposFiltro,
    GruposAcciones,
    PageHeader,
    FooterTotalDocs,
    FooterFuente,
    // icons
    ReloadOutlined,
    FileAddOutlined,
    FileZipOutlined,
    UsergroupAddOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    IssuesCloseOutlined,
    FormOutlined
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const isModalVisible = ref(false);
    const ismodalQuery = ref(false);
    const ismodalContacto = ref(false);
    const grupo = ref(null);
    const data = ref([]);
    const isLoading = ref(false);
    const isDisabled = ref(true);
    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });

    // const goBack = () => router.back();

    const fetchGrupos = (currentPage = 1) => {
      isLoading.value = true;
      const filter = store.getters["grupos/filter"];
      const search = store.getters["grupos/search"];

      GrupoApi.getAll(currentPage, filter, search)
        .then((response) => {
          console.log(response);
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      store.dispatch("grupos/cleanFilters");
      UsuariosApi.userLogAdd("Vigilancia", "Grupos", establecimiento);
      fetchGrupos();
    });

    const { establecimiento } = store.state.user.usuario;
    const showModal = () => {
      isModalVisible.value = true;
    };

    const showModalQuery = () => {
      ismodalQuery.value = true;
    };

    const showModalGrupo = () => {
      ismodalContacto.value = true;
    };

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          grupo.value = toRaw(selectedRows[0]);
        }
      };
    });

    const customRow = (record) => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const closeModal = () => {
      isModalVisible.value = false;
      ismodalQuery.value = false;
      ismodalContacto.value = false;
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      state.rowActiveStatus = [];
    };

    const handleAdd = () => {
      grupo.value = {
        idgrupo: "",
        denominacion: "",
        idquery: "",
        query: "",
        canal: ""
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleChangeState = () => {
      const activateMsg = grupo.value.activo === "1" ? "desactivará" : "activará";
      const actionMsg = grupo.value.activo === "1" ? "desactivado" : "activado";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el grupo ${grupo.value.denominacion}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const valor = grupo.value.activo === "1" ? "0" : "1";
          GrupoApi.CambiarEstadoOne(toRaw(grupo.value.idgrupo), valor)
            .then((res) => {
              fetchGrupos(currentPage.value);
              clearSelectedArrays();

              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el grupo satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el grupo '${grupo.value.denominacion}'`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          GrupoApi.deleteOne(toRaw(grupo.value.idgrupo))
            .then((res) => {
              fetchGrupos(currentPage.value);
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    watch(currentPage, () => {
      fetchGrupos(currentPage.value);
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    const reloadFetch = () => {
      fetchGrupos(currentPage.value);
    };

    const handleCode = () => {
      showModalQuery();
    };

    const handleContactos = () => {
      showModalGrupo();
    };

    // const routes = [
    //   {
    //     path: "menu",
    //     breadcrumbName: "Vigilancia"
    //   },
    //   {
    //     path: "/mensajes",
    //     breadcrumbName: "Grupos de SMS"
    //   }
    // ];

    return {
      // goBack,
      // routes,
      columns,
      data,
      customRow,
      rowSelection,
      isModalVisible,
      showModalQuery,
      isDisabled,
      ismodalContacto,
      handleAdd,
      ismodalQuery,
      isLoading,
      handleEdit,
      handleCode,
      reloadFetch,
      fetchGrupos,
      handleContactos,
      handleChangeState,
      handleDelete,
      clearSelectedArrays,
      grupo,
      pageSize,
      currentPage,
      totalDocs,
      closeModal
    };
  }
};
</script>

<style></style>
