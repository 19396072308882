import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_SERVICE_CREATE_ARRAY = "/api/sivi_data/ServiceArray/V1";
const API_SERVICE_DELETE_ARRAY = "/api/sivi_data/V1/update_delete";

export default {
  getAll: (currentPage, filter, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_sms_grupo",
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@filtros_AND",
          value: "g.activo",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${filter}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "g.denominacion|qr.denominacion",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "sms_grupo",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string|unique"
        },
        {
          name: "idquery",
          value: payload.idquery,
          type: "string"
        },
        {
          name: "canal",
          value: payload.canal,
          type: "int"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    return post(API_SERVICE, request);
  },

  editOne: (payload, idgrupo, isUnique) => {
    const request = {
      _type: 3,
      _table: "sms_grupo",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "canal",
          value: payload.canal,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idgrupo, request);
  },

  deleteOne: (idgrupo) => {
    const request = {
      _type: 3,
      _table: "sms_grupo",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idgrupo, request);
  },

  CambiarEstadoOne: (idgrupo, activo) => {
    const request = {
      _type: 3,
      _table: "sms_grupo",
      _petitions: [
        {
          name: "activo",
          value: activo,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idgrupo, request);
  },

  getAllContactos: (currentPage, idgrupoo, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_contacto_grupo",
          type: "string"
        },
        {
          name: "@IDGRUPO",
          value: `%${idgrupoo}%`,
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@filtros_OR",
          value:
            "rg.documento|CONCAT(rg.apellidopaterno, ' ', rg.apellidomaterno, ' ', rg.nombre)|CONCAT(rg.apellidopaterno, ' ', rg.apellidomaterno, ', ', rg.nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllContactos_IdPeriodo: (currentPage, idgrupoo, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_segu_reg_paciente_idgrupo",
          type: "string"
        },
        {
          name: "@IDGRUPO",
          value: `${idgrupoo}`,
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@filtros_OR",
          value:
            "p.documento|CONCAT(p.apellidopaterno, ' ', p.apellidomaterno, ' ', p.nombre)|CONCAT(p.apellidopaterno, ' ', p.apellidomaterno, ', ', p.nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addAllContactos: (valores) => {
    const request = {
      _type: 2,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "@columns",
          value: "idpaciente,idgrupo",
          type: "string"
        },
        {
          name: "@campos",
          value: valores, // "2,3;5,6;7,7",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_CREATE_ARRAY, request);
  },

  deleteContactos: (valores) => {
    const request = {
      _type: 3,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "@ids",
          value: valores,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_DELETE_ARRAY, request);
  }
};
