import { post, put } from "./base";

const API_SERVICE = "/api/Service/V1";

export default {
  editOneQuery: (payload, idquery, isUnique) => {
    const request = {
      _type: 3,
      _table: "query",
      _petitions: [
        {
          name: "denominacion",
          value: payload.query,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "query"
        }
      ]
    };

    return put(API_SERVICE + "/" + idquery, request);
  },

  addOneVacio: (idindicador = "") => {
    const request = {
      _type: 2,
      _table: "query",
      _petitions: [
        {
          name: "denominacion",
          value: "",
          type: "string"
        },
        {
          name: "descripcion",
          value: "Espacio para query de indicador id " + idindicador,
          type: "string"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    return post(API_SERVICE, request);
  },

  addOneVacioConNombre: (nombre = "") => {
    const request = {
      _type: 2,
      _table: "query",
      _petitions: [
        {
          name: "denominacion",
          value: nombre,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE, request);
  },

  editOne: (nombre = "", idquery) => {
    const request = {
      _type: 3,
      _table: "query",
      _petitions: [
        {
          name: "denominacion",
          value: nombre,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idquery, request);
  }
};
