<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="flex flex-wrap md:flex-nowrap"
  >
    <div class="w-full">
      <a-form-item label="Estado" name="filter" class="mb-2 lg:w-60">
        <a-select v-model:value="formState.filter">
          <a-select-option value="">Todos</a-select-option>
          <a-select-option value="1">Activos</a-select-option>
          <a-select-option value="0">Desactivados</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Buscar" class="mb-2 lg:w-60" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>
    </div>

    <div class="flex">
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchGrupos"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    store.dispatch("grupos/cleanFilters");

    const formState = reactive({
      filter: store.getters["grupos/filter"],
      search: store.getters["grupos/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search } = toRaw(formState);
          // console.log(filter, search);
          store.dispatch("grupos/setFilter", filter);
          store.dispatch("grupos/setSearch", search);
          emit("fetchGrupos");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "";
      formState.search = "";
      store.dispatch("grupos/cleanFilters");
      emit("fetchGrupos");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
