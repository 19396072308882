<template>
  <a-modal
    :visible="true"
    :title="nombre"
    class="max-w-sm md:max-w-5xl"
    width="100%"
    @close="closeModal"
    @cancel="handleCancel"
    @ok="handleOk"
    :ok-button-props="{ style: { display: 'none' } }"
    okText="Aceptar"
    :maskClosable="false"
  >
    <a-tabs v-model:activeKey="activeKey" class="text-center">
      <a-tab-pane key="1" tab="LISTA PACIENTES">
        <a-form class="text-right" layout="vertical" :model="formState" ref="formRef">
          <a-form-item label="Buscar Paciente" name="search" class="w-full">
            <a-input-search
              v-model:value="search"
              enter-button
              @search="onSearch"
            />
          </a-form-item>

          <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
            <a-table
              :row-selection="rowSelection2"
              :custom-row="customRow2"
              :columns="columns"
              :data-source="contactos"
              size="small"
              :pagination="false"
              :loading="isLoading"
              rowKey="idcontacto"
              :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
            >
              <template #rangepages="{ index }">
                <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
              </template>

              <template #activo="{ text: activo }">
                <span>
                  <a-tag :color="activo === '1' ? 'green' : 'volcano'">
                    {{ activo === "1" ? "Activado" : "Desactivado" }}
                  </a-tag>
                </span>
              </template>
            </a-table>

            <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
              <div class="flex">
                <a-pagination
                  v-model:current="curretPage"
                  v-model:pageSize="pageSize"
                  :total="totalDocs"
                />
              </div>
            </footer>
            <p>Total de registros: {{ totalDocs }}</p>
            <div class="text-center">            
              <a-button @click="eliminar" class="text-right ant-btn ant-btn-danger">Eliminar</a-button>
            </div>
          </div>
        </a-form>
      </a-tab-pane>

      <a-tab-pane key="2" tab="AGREGAR PACIENTE" force-render>
        <a-form class="text-right" layout="vertical" :model="formState" ref="formRef">
          <a-form-item label="Buscar Paciente" name="search" class="w-full">
            <a-input-search
              v-model:value="search2"
              enter-button
              @search="onSearch2"
            />
          </a-form-item>

          <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
            <a-table
              :row-selection="rowSelection"
              :custom-row="customRow"
              :columns="columns2"
              :data-source="contactosAll"
              size="small"
              :pagination="false"
              :loading="isLoading"
              rowKey="idpaciente"
              :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
            >
              <template #rangepages="{ index }">
                <span> {{ (curretPage2 - 1) * 10 + index + 1 }} </span>
              </template>

              <template #activo="{ text: activo }">
                <span>
                  <a-tag :color="activo === '1' ? 'green' : 'volcano'">
                    {{ activo === "1" ? "Activado" : "Desactivado" }}
                  </a-tag>
                </span>
              </template>
            </a-table>

            <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
              <div class="flex">
                <a-pagination
                  v-model:current="curretPage2"
                  v-model:pageSize="pageSize"
                  :total="totalDocs2"
                />
              </div>
            </footer>
            <p>Total de registros: {{ totalDocs2 }}</p>
            <div class="text-center">            
              <a-button @click="guardar" class="text-right ant-btn ant-btn-primary">Guardar</a-button>
            </div>
          </div>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import DestinatarioApi from "@/api/destinatario";
import { reactive, onMounted, toRaw, ref, computed, watch } from "vue";
import { notification } from "ant-design-vue";
import { columns } from "./utilsPacientes";
import { columns2 } from "./utilsPacientesAll";
import GrupoApi from "@/api/sms_grupo";

export default {
  props: {
    grupo: {
      type: Object
    }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const grupos = ref([]);
    const contactos = ref([]);
    const contactosAll = ref([]);
    const formRef = ref();
    const nombre = ref("");
    const curretPage = ref(1);
    const curretPage2 = ref(1);
    const search = ref("");
    const search2 = ref("");
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const totalDocs2 = ref(null);
    const isLoading = ref(false);
    const formState = reactive(props.grupo);
    const activeKey = ref('1');
    const state = reactive({
      selectedRowKeys: []
    });

    const state2 = reactive({
      selectedRowKeys: []
    });

    const closeModal = () => {
      emit("closeModal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          state.selectedRowKeys = selectedRowKeys;
        }
      };
    });

    const rowSelection2 = computed(() => {
      return {
        selectedRowKeys: state2.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          state2.selectedRowKeys = selectedRowKeys;
        }
      };
    });

    const selectRow2 = (record) => {
      const selectedRowKeys = [...state2.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.idusuario) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.idusuario), 1);
      } else {
        selectedRowKeys.push(record.idusuario);
      }
      state2.selectedRowKeys = selectedRowKeys;
    };

    const selectRow = (record) => {
      const selectedRowKeys = [...state.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.idusuario) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.idusuario), 1);
      } else {
        selectedRowKeys.push(record.idusuario);
      }
      state.selectedRowKeys = selectedRowKeys;
    };

    const customRow = (record) => {
      return {
        onClick: () => {
          selectRow(record);
        }
      };
    };

    const customRow2 = (record) => {
      return {
        onClick: () => {
          selectRow2(record);
        }
      };
    };

    const eliminar = () => {
      const ids = toRaw(state2.selectedRowKeys);
      var values = "";

      ids.forEach(element => {
        values += element + "|";
      });

      values = values.substring(0, values.length - 1);
      
      GrupoApi.deleteContactos(values)     
        .then((response) => {
          notification.success({
            message: "Operación exitosa",
            description: "Se ha elimanado satisfactoriamente los contactos"
          });

          curretPage2.value = 1;
          curretPage.value = 1;
          state2.selectedRowKeys = [];
          getPacientesIdGrupo(1);
          getPacientes(1);
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    };

    const guardar = () => {
      const ids = toRaw(state.selectedRowKeys);
      var values = "";

      ids.forEach(element => {
        values += element + "," + formState.idgrupo + ";";
      });

      values = values.substring(0, values.length - 1);

      GrupoApi.addAllContactos(values)     
        .then((response) => {
          notification.success({
            message: "Operación exitosa",
            description: "Se ha creado satisfactoriamente los contactos"
          });

          curretPage2.value = 1;
          curretPage.value = 1;
          state.selectedRowKeys = [];
          getPacientesIdGrupo(1);
          getPacientes(1);
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    };

    const onSearch = (value) => {
      getPacientes(1);
    };

    const onSearch2 = (value) => {
      getPacientesIdGrupo(1);
    };

    const getPacientes = (currentPage = 1) => {
      isLoading.value = true;
      GrupoApi.getAllContactos(currentPage, formState.idgrupo, search.value)
        .then((response) => {
          contactos.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getPacientesIdGrupo = (currentPage = 1) => {
      isLoading.value = true;
      GrupoApi.getAllContactos_IdPeriodo(currentPage, formState.idgrupo, search2.value)
        .then((response) => {
          console.log(response);
          contactosAll.value = response.data.docs;
          totalDocs2.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
        });
    };

    const handleOk = () => {};

    watch(curretPage, () => {
      getPacientes(curretPage.value);
    });

    watch(curretPage2, () => {
      getPacientesIdGrupo(curretPage2.value);
    });

    const reloadFetch = () => {
      getPacientes(curretPage.value);
    };

    const reloadFetch2 = () => {
      getPacientesIdGrupo(curretPage2.value);
    };

    onMounted(() => {
      nombre.value = "Contactos " +  formState.denominacion;
      getPacientes(1);
      getPacientesIdGrupo(1);
    });

    return {
      // layout,
      closeModal,
      contactos,
      grupos,
      handleOk,
      rowSelection,
      columns,
      eliminar,
      search,
      search2,
      customRow,
      reloadFetch2,
      formRef,
      rowSelection2,
      columns2,
      customRow2,
      reloadFetch,
      onSearch,
      onSearch2,
      guardar,
      activeKey,
      formState,
      curretPage,
      contactosAll,
      nombre,
      pageSize,
      totalDocs,
      handleCancel,
      isLoading,
      curretPage2,
      totalDocs2
    };
  }
};
</script>
