<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar grupo' : 'Nuevo grupo']"
    class="max-w-sm md:max-w-lg"
    @close="closeModal"
    @cancel="handleCancel"
    @ok="handleOk"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item label="Nombre del grupo" name="denominacion" class="w-full">
        <a-input v-model:value="formState.denominacion" />
      </a-form-item>

      <a-form-item label="Nombre de la consulta SQL" name="query" class="w-full">
        <a-input v-model:value="formState.query" />
      </a-form-item>

      <a-form-item label="Número de Canal" name="canal" class="w-full">
        <a-input v-model:value="formState.canal" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import VigilanciaMensajeApi from "@/api/sms_mensajes";
import { reactive, onMounted, toRaw, ref, computed, watch } from "vue";
import { notification } from "ant-design-vue";
import GrupoApi from "@/api/sms_grupo";
import QueryApi from "@/api/query";
export default {
  props: {
    grupo: {
      type: Object
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["closeModal", "fetchData", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    const formState = reactive(props.grupo);
    const isEdit = !!props.grupo.idgrupo;
    const denominacion = props.grupo.denominacion;

    const closeModal = () => {
      emit("closeModal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const fetchData = () => {};

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);

          let response = {};
          let response2 = {};

          if (isEdit) {
            const payload = toRaw(formState);
            console.log(payload);
            response = await QueryApi.editOne(payload.query, payload.idquery);

            const isDenominacion = formState.denominacion !== denominacion;
            response2 = await GrupoApi.editOne(payload, payload.idgrupo, isDenominacion);

            if (response2.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: "Registro grupo editado"
              });
            }
            emit("closeModal");
            emit("fetchData", props.currentPage);
            emit("clearSelectedArrays");
          } else {
            const payload = toRaw(formState);
            const response = await QueryApi.addOneVacioConNombre(formState.query);

            if (response.status == 200) {
              payload.idquery = response.data[0].id;
              const response2 = await GrupoApi.addOne(payload);

              if (response2.status == 200) {
                notification.success({
                  message: "Operación exitosa",
                  description: "Registro grupo creado"
                });
              }
            }
            emit("closeModal");
            emit("fetchData", props.currentPage);
            emit("clearSelectedArrays");
          }
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchData();
    });

    const rules = {
      denominacion: [
        {
          required: true,
          message: "El campo denominacion no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 60,
          message: "La cantidad máxima de caracteres es de 60",
          trigger: "blur"
        }
      ],
      query: [
        {
          required: true,
          message: "El campo query no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 20,
          message: "La cantidad máxima de caracteres es de 20",
          trigger: "blur"
        }
      ]
    };

    return {
      // layout,
      closeModal,
      handleOk,
      rules,
      formRef,
      formState,
      handleCancel,
      isEdit
    };
  }
};
</script>
