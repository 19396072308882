export const columns = [
    {
      title: "N°",
      key: "index",
      width: 50,
      slots: { customRender: "rangepages" }
    },
    {
      title: "NOMBRE DEL GRUPO",
      dataIndex: "denominacion",
      key: "denominacion",
      width: 280,
      ellipsis: true
    },
    {
      title: "QUERY",
      dataIndex: "query",
      key: "query",
      width: 180,
      ellipsis: true
    },
    {
      title: "ESTADO",
      dataIndex: "activo",
      key: "activo",
      slots: { customRender: "activo" },
      width: 120
    }
  ];