import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_contactos",
          type: "string"
        },
        {
          name: "@page",
          value: payload?.currentPage,
          type: "int"
        }
      ]
    };

    if (payload?.filter && payload?.filter !== "all") {
      const { filter = "all", search = "" } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "c.activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "documento|CONCAT(p.apellidopaterno, ' ' , p.apellidomaterno, ' ', p.nombre)",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload?.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "documento|CONCAT(p.apellidopaterno, ' ' , p.apellidomaterno, ' ', p.nombre)",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getTipoPaciente: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_sys_tipopaciente",
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_QUERY, request);
  },

  getGrupo: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_sms_grupo",
          type: "string"
        }
      ]
    };
    return post(API_SERVICE_QUERY, request);
  },

  getPaciente: (currentPage, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente_contacto",
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@filtros_OR",
          value: "documento|CONCAT(apellidopaterno, ' ' , apellidomaterno, ' ' , nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_QUERY, request);
  },

  getTipoDeficiencia: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_sys_tipodeficiencia",
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "idpaciente",
          value: payload.idpaciente,
          type: "string"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "string"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE, request);
  },

  editOne: (payload, iddestinatario) => {
    const request = {
      _type: 3,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "idpaciente",
          value: payload.idpaciente,
          type: "string"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "string"
        }
      ]
    };
    return put(API_SERVICE + "/" + iddestinatario, request);
  },

  deleteOne: (iddestinatario) => {
    const request = {
      _type: 3,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + iddestinatario, request);
  },

  CambiarEstadoOne: (iddestinatario, activo) => {
    const request = {
      _type: 3,
      _table: "sms_contacto",
      _petitions: [
        {
          name: "activo",
          value: activo,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + iddestinatario, request);
  }
};
