<template>
  <p class="text-black font-semibold my-4">
    Fuente: {{ fuente }} {{ fecha ? "Actualizado " + fecha : "" }}
  </p>
</template>

<script>
export default {
  props: {
    fuente: {
      type: String,
      default: ""
    },
    fecha: {
      type: String,
      default: ""
    }
  }
};
</script>

<style></style>
