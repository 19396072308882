<template>
  <a-modal
    :visible="true"
    width="1000px"
    title="Editor de código"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Guardar"
  >
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <a-form :model="formState" ref="formRef">
        <a-form-item label="Nombre">
          <a-input v-model:value="formState.query" placeholder="Nombre de la query SQL" />
        </a-form-item>
        <a-form-item label="" name="descripcion">
          <a-textarea
            :rows="12"
            v-model:value="formState.descripcion"
            placeholder="INSERT QUERY SQL"
          />
        </a-form-item>
      </a-form>
    </fieldset>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import QueryApi from "@/api/query";

export default {
  props: {
    grupo: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    /* eslint-disable vue/no-setup-props-destructure */

    const grupo = props.grupo;
    const formRef = ref();
    const formState = reactive(grupo);
    const denominacion = props.grupo.query;

    const handleCancel = () => {
      emit("closeModal");
    };

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";

          try {
            const isUnique = denominacion !== formState.query;
            const idquery = toRaw(formState)?.idquery;

            response = await QueryApi.editOneQuery(payload, idquery, isUnique);
            msgNotificacion = "La consulta ha sido modificado correctamente.";

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchData");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    return {
      closeModal,
      handleCancel,
      // onCmCodeChange,
      formState,
      handleOk,
      formRef
    };
  }
};
</script>
