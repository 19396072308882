export const columns2 = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "DOCUMENTO",
    dataIndex: "documento",
    key: "documento",
    width: 100,
    ellipsis: true
  },
  {
    title: "NOMBRES",
    dataIndex: "nombres",
    key: "nombres",
    width: 180,
    ellipsis: true
  }
];
